<template>
    <div class="menu-body" v-if="$oidc.userProfile.roles.includes(roles.PLANTSUBPILLARLEADER) ||
        $oidc.userProfile.roles.includes(roles.PLANTPOLEADER) ||
        $oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER)
        ">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con">
            <div class="mat-info-con even-space-vertical-align">
                <div class="plant-dd" style="width: 100%; display: flex">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> Committed Year </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="committed_year"
                            v-model="committedYr" disabled @change="filterHandler()">
                            <option v-for="year in committedYear" v-bind:key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="plant" class="form-label select-lable lable-text"> Plant </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="plantId" disabled
                            :value="plantSelectedInAsmtHome" @change="plantChanged($event); filterHandler();">
                            <option v-for="   plant    in    plantList   " v-bind:key=" plant.plantId "
                                :value=" plant.plantId ">
                                {{ plant.plantName }}, {{ plant.regionCode }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="workcenter" class="form-label select-lable lable-text"> Work Center </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="workcenterId"
                            :value=" prevSelectedWorkCenter " @change=" workcenterChanged($event); filterHandler(); ">
                            <option v-for="   wc    in    workCenterList   " v-bind:key=" wc.workcenterId "
                                :value=" wc.workcenterId ">
                                {{ wc.workcenterName }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="pillar" class="form-label select-lable lable-text"> Pillar </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="pillarId"
                            :value=" pillarPrevSelected " @change="
                                pillarChanged($event);
                                filterHandler();
                            ">
                            <option v-for="  pillar   in   pillarList  " :key=" pillar.pillarId " :value=" pillar.pillarId ">
                                {{ pillar.pillarCode }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div style="display: flex; justify-content: right; padding: 0 2rem">
                <div class="edit-button-div">
                    <b-button id="back-btn" class="btn cancel-save-btn ed-st-btn" pill variant="outline-primary"
                        @click=" $router.go(-1) "> Go back </b-button>

                    <b-button class="btn save-submit-btn ed-st-btn" pill block @click=" updateHandle "> Update </b-button>
                </div>
            </div>
            <div style="display: flex; flex-wrap: wrap">
                <div class="machine-action-tracker">
                    <b-table-simple responsive outlined id="machine-action-tracker" sticky-header
                        class="machine-action-table scroll gn-table-b">
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th v-for="  header   in   machineDetailHeader  " :key=" header.key ">{{ header.label
                                    }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody v-if=" machineDetail && machineDetail.length !== 0 ">
                            <b-tr v-for="(  rowData, index  ) in   machineDetail  " :key=" index ">
                                <b-td v-if=" !(index % 3) " rowspan="3" class="machine-action-table-machine">
                                    {{ rowData.machineName }}
                                </b-td>
                                <b-td v-if=" !(index % 3) " rowspan="3" class="machine-action-table-machine">
                                    {{ rowData.machineStatus }}
                                </b-td>
                                <b-td class="machine-action-tracker-label">{{ rowData.labels }}</b-td>

                                <b-td>
                                    <div class="input-div" v-if=" rowData.jan.hasOwnProperty('needInput') ">
                                        <input class="input-f" v-model=" rowData.jan.value " min="0"
                                            :disabled=" !rowData.jan.needInput " @input=" changingF(index, 'jan') "
                                            pattern="^[0-9]" maxlength="3"
                                            @keydown=" isValidFormat($event, rowData.labels) "
                                            @blur=" verify(index, 'jan', $event, rowData.labels, rowData.jan.value) " />
                                    </div>
                                    <div v-else>{{ rowData.jan }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if=" rowData.feb.hasOwnProperty('needInput') ">
                                        <input class="input-f" v-model=" rowData.feb.value " min="0"
                                            :disabled=" !rowData.feb.needInput " @input=" changingF(index, 'feb') "
                                            pattern="^[0-9]" maxlength="3"
                                            @keydown=" isValidFormat($event, rowData.labels) "
                                            @blur=" verify(index, 'feb', $event, rowData.labels, rowData.feb.value) " />
                                    </div>
                                    <div v-else>{{ rowData.feb }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if=" rowData.mar.hasOwnProperty('needInput') ">
                                        <input class="input-f" v-model=" rowData.mar.value " min="0"
                                            :disabled=" !rowData.mar.needInput " @input=" changingF(index, 'mar') "
                                            pattern="^[0-9]" maxlength="3"
                                            @keydown=" isValidFormat($event, rowData.labels) "
                                            @blur=" verify(index, 'mar', $event, rowData.labels, rowData.mar.value) " />
                                    </div>
                                    <div v-else>{{ rowData.mar }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if=" rowData.apr.hasOwnProperty('needInput') ">
                                        <input class="input-f" v-model=" rowData.apr.value " min="0"
                                            :disabled=" !rowData.apr.needInput " @input=" changingF(index, 'apr') "
                                            pattern="^[0-9]" maxlength="3"
                                            @keydown=" isValidFormat($event, rowData.labels) "
                                            @blur=" verify(index, 'apr', $event, rowData.labels, rowData.apr.value) " />
                                    </div>
                                    <div v-else>{{ rowData.apr }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if=" rowData.may.hasOwnProperty('needInput') ">
                                        <input class="input-f" v-model=" rowData.may.value " min="0"
                                            :disabled=" !rowData.may.needInput " @input=" changingF(index, 'may') "
                                            pattern="^[0-9]" maxlength="3"
                                            @keydown=" isValidFormat($event, rowData.labels) "
                                            @blur=" verify(index, 'may', $event, rowData.labels, rowData.may.value) " />
                                    </div>
                                    <div v-else>{{ rowData.may }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if=" rowData.jun.hasOwnProperty('needInput') ">
                                        <input class="input-f" v-model=" rowData.jun.value " min="0"
                                            :disabled=" !rowData.jun.needInput " @input=" changingF(index, 'jun') "
                                            pattern="^[0-9]" maxlength="3"
                                            @keydown=" isValidFormat($event, rowData.labels) "
                                            @blur=" verify(index, 'jun', $event, rowData.labels, rowData.jun.value) " />
                                    </div>
                                    <div v-else>{{ rowData.jun }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if=" rowData.jul.hasOwnProperty('needInput') ">
                                        <input class="input-f" v-model=" rowData.jul.value " min="0"
                                            :disabled=" !rowData.jul.needInput " @input=" changingF(index, 'jul') "
                                            pattern="^[0-9]" maxlength="3"
                                            @keydown=" isValidFormat($event, rowData.labels) "
                                            @blur=" verify(index, 'jul', $event, rowData.labels, rowData.jul.value) " />
                                    </div>
                                    <div v-else>{{ rowData.jul }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if=" rowData.aug.hasOwnProperty('needInput') ">
                                        <input class="input-f" v-model=" rowData.aug.value " min="0"
                                            :disabled=" !rowData.aug.needInput " @input=" changingF(index, 'aug') "
                                            pattern="^[0-9]" maxlength="3"
                                            @keydown=" isValidFormat($event, rowData.labels) "
                                            @blur=" verify(index, 'aug', $event, rowData.labels, rowData.aug.value) " />
                                    </div>
                                    <div v-else>{{ rowData.aug }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if=" rowData.sep.hasOwnProperty('needInput') ">
                                        <input class="input-f" v-model=" rowData.sep.value " min="0"
                                            :disabled=" !rowData.sep.needInput " @input=" changingF(index, 'sep') "
                                            pattern="^[0-9]" maxlength="3"
                                            @keydown=" isValidFormat($event, rowData.labels) "
                                            @blur=" verify(index, 'sep', $event, rowData.labels, rowData.sep.value) " />
                                    </div>
                                    <div v-else>{{ rowData.sep }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if=" rowData.oct.hasOwnProperty('needInput') ">
                                        <input class="input-f" v-model=" rowData.oct.value " min="0"
                                            :disabled=" !rowData.oct.needInput " @input=" changingF(index, 'oct') "
                                            pattern="^[0-9]" maxlength="3"
                                            @keydown=" isValidFormat($event, rowData.labels) "
                                            @blur=" verify(index, 'oct', $event, rowData.labels, rowData.oct.value) " />
                                    </div>
                                    <div v-else>{{ rowData.oct }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if=" rowData.nov.hasOwnProperty('needInput') ">
                                        <input class="input-f" v-model=" rowData.nov.value " min="0"
                                            :disabled=" !rowData.nov.needInput " @input=" changingF(index, 'nov') "
                                            pattern="^[0-9]" maxlength="3"
                                            @keydown=" isValidFormat($event, rowData.labels) "
                                            @blur=" verify(index, 'nov', $event, rowData.labels, rowData.nov.value) " />
                                    </div>
                                    <div v-else>{{ rowData.nov }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if=" rowData.dec.hasOwnProperty('needInput') ">
                                        <input class="input-f" v-model=" rowData.dec.value " min="0"
                                            :disabled=" !rowData.dec.needInput " @input=" changingF(index, 'dec') "
                                            pattern="^[0-9]" maxlength="3"
                                            @keydown=" isValidFormat($event, rowData.labels) "
                                            @blur=" verify(index, 'dec', $event, rowData.labels, rowData.dec.value) " />
                                    </div>
                                    <div v-else>{{ rowData.dec }}</div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tbody v-if=" machineDetail && machineDetail.length === 0 ">
                            <tr>
                                <td class="text-center" style="height: 380px" rowspan="6"
                                    :colspan=" machineDetailHeader.length ">
                                    <img alt="no data" src="@/assets/No_Data.svg" />
                                    <div class="ed-date">No Data Found</div>
                                </td>
                            </tr>
                        </b-tbody>
                        <b-tfoot> </b-tfoot>
                    </b-table-simple>
                </div>
            </div>
        </div>
        <b-modal id="pillarAlert" :data-backdrop=" true " :no-close-on-backdrop=" true " :no-close-on-esc=" true " size="sm"
            ref="pillarMsg" hide-footer hide-header-close :title=" headerforPillar " :visible=" popupPillar ">
            <div class="row col-12 page-content-regular">
                <p>
                    <span class="page-heading-tab">
                        {{ msgForPillar }}
                    </span>
                </p>
            </div>
            <div class="button-div" style="padding-left: 18rem">
                <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem"
                    class="btn cancel-save-btn button-sm" @click=" OkForPillar() "> OK </b-button>
            </div>
        </b-modal>
        <MessagePopupvue :routToBack=" false " class="alert" :message=" errorMsg " :show=" showPopup "
            :headText=" headText " />
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import MessagePopupvue from "@/components/common/MessagePopup.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import { mapGetters } from "vuex";
import roles from "@/common/constants/roles";
export default {
    name: "CurrentScoreSetting",
    data() {
        return {
            roles: roles,
            showLoader: true,
            plantList: [],
            workCenterList: [],
            committedYear: [],
            pillarList: [],
            plantId: 1,
            committedYr: new Date().getFullYear(),
            machineDetailHeader: [
                {
                    key: "machineName",
                    label: "Machine",
                    sortable: false
                },
                {
                    key: "machineStatus",
                    label: "Classification",
                    sortable: false
                },
                {
                    key: "labels",

                    sortable: false
                },
                {
                    key: "jan",
                    label: "Jan",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "feb",
                    label: "Feb",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "mar",
                    label: "Mar",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "apr",
                    label: "Apr",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "may",
                    label: "May",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "jun",
                    label: "Jun",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "jul",
                    label: "Jul",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "aug",
                    label: "Aug",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "sep",
                    label: "Sep",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oct",
                    label: "Oct",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "nov",
                    label: "Nov",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "dec",
                    label: "Dec",
                    sortable: false,
                    variant: "success"
                }
            ],
            rawMachineDetail: [],
            machineDetail: [],
            errorMsg: "",
            headText: "",
            showPopup: false,
            popupPillar: false,
            headerforPillar: "",
            msgForPillar: ""
        };
    },
    components: {
        Breadcrumb,
        Loader,
        MessagePopupvue
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 4,
            subMenu: 3
        });
        this.$store.dispatch("breadcrumb", {
            title: "Plant Optimization Step Audit - Edit",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Machine Replication Step Audit", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        this.getCommittedYear();
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "prevSelectedWorkCenter", "loggedInUserData", "pillarPrevSelected"])
    },
    methods: {
        getCommittedYear() {
            ApiCalls.httpCallDetails(Api.MRCOMMITTEDYEAR, "get").then((data) => {
                this.committedYear = data.data;
                this.committedYr = new Date().getFullYear();
                this.getPlantDetails();
            });
        },
        getPlantDetails() {
            ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
                this.plantList = data.data;
                let plantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
                let index = 0;
                if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId == this.plantSelectedInAsmtHome);
                else if (this.loggedInUserData.plantId) {
                    let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                } else if (this.loggedInUserData.regionName) {
                    let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                }
                this.plantId = this.plantList[index];
                this.$store.dispatch("plantSelectedInAsmtHome", plantId);
                this.getWorkCenterDetails();
            });
        },
        plantChanged(event) {
            console.log("plantChanged");
            this.showLoader = true;
            this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
            this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
            this.$store.dispatch("prevSelectedWorkCenter", 0);
            this.getWorkCenterDetails();
        },
        getWorkCenterDetails() {
            ApiCalls.httpCallDetails(Api.MRWORKCENTERALL, "post", { regionId: this.plantId.regionId }).then((data) => {
                this.workCenterList = data.data;

                let index = 0;
                if (this.prevSelectedWorkCenter) index = this.workCenterList.findIndex((el) => el.workcenterId == this.prevSelectedWorkCenter);

                this.wcId = this.workCenterList[index].workcenterId;
                this.$store.dispatch("prevSelectedWorkCenter", this.wcId);
                this.getPillarDetails();
            });
        },
        workcenterChanged(event) {
            this.showLoader = true;
            this.$store.dispatch("prevSelectedWorkCenter", event.target.value);
            let changedWcId = this.workCenterList.find((el) => el.workcenterId == event.target.value);
            this.wcId = changedWcId.workcenterId;
            this.getPillarDetails();
        },
        getPillarDetails() {
            ApiCalls.httpCallDetails(Api.MRPILLARDETAILS, "get").then((data) => {
                this.pillarList = data.data;
                let index = 0;
                if (this.pillarPrevSelected) index = this.pillarList.findIndex((el) => el.pillarId == this.pillarPrevSelected);
                this.pilrId = this.pillarList[index].pillarId;
                this.$store.dispatch("pillarPrevSelected", this.pilrId);
                this.filterHandler();
            });
        },
        pillarChanged(event) {
            this.showLoader = true;
            this.$store.dispatch("pillarPrevSelected", event.target.value);
            this.filterHandler();
        },
        filterHandler() {
            //api call for every change in filters
            let machinewisePayload = {
                plantId: this.plantId.plantId,
                workcenterId: this.wcId,
                pillarId: this.pillarPrevSelected,
                committedYear: this.committedYr
            };
            this.showLoader = true;

            ApiCalls.httpCallDetails(Api.MRMACHINESCORE, "post", machinewisePayload).then((data) => {
                this.resData = data;
                this.showLoader = false;
                let temp = [];
                this.resData.data.map((pillarData, index) => {
                    console.log(pillarData.trackerId);
                    if (pillarData.trackerId) {
                        temp.push({
                            machineName: pillarData.machineName,
                            labels: "Target",
                            machineStatus: pillarData.machineStatus,
                            ...pillarData.target
                        });
                        let tempCurrent = {},
                            day = 8, // day is one we are comparing with
                            tempAudit = {},
                            pos1 = 0,
                            pos2 = 0,
                            currentMonth = new Date().getDate() < day ? Number(new Date().getMonth() - 1) : Number(new Date().getMonth()),
                            auditMonth = new Date().getDate() < day ? Number(new Date().getMonth() - 1) : Number(new Date().getMonth());
                        for (const month in pillarData.current) {
                            if (Object.hasOwnProperty.call(pillarData.current, month)) {
                                const element = pillarData.current[month];
                                tempCurrent[month] = { value: element, needInput: pos1 === currentMonth };
                                pos1++;
                            }
                            if (Object.hasOwnProperty.call(pillarData.auditScore, month)) {
                                const element = pillarData.auditScore[month];
                                tempAudit[month] = { value: element, needInput: pos2 === auditMonth };
                                pos2++;
                            }
                        }
                        temp.push({
                            labels: "Current",
                            ...tempCurrent,
                            trackerId: { value: pillarData.trackerId }
                        });

                        temp.push({
                            labels: "Audit Score",
                            ...tempAudit,
                            trackerId: { value: pillarData.trackerId }
                        });
                    }
                });

                console.log(temp);
                this.machineDetail = temp;
                this.rawMachineDetail = JSON.parse(JSON.stringify(temp));
            });
        },
        isNumber(evt) {
            let charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && isNaN(Number(evt.key)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        isDecimal(evt) {
            let charCode = evt.which ? evt.which : evt.keyCode;
            console.log(charCode);
            if (charCode === 190) {
                return true;
            }

            if (charCode > 31 && isNaN(Number(evt.key)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        isValidFormat(e, inVal) {
            console.log("inVal", inVal);
            if (inVal == "Audit Score" && this.pillarPrevSelected == 4) {
                this.isDecimal(e);
            } else {
                this.isNumber(e);
            }
        },
        changingF(index, month, event) {
            console.log("changingF", pillarId, index, month, event);
        },
        verifyAudit(index, month, event, field) {
            let auditVal = event.target.value;
            let pillarId = this.pillarPrevSelected;
            if (pillarId == 4 && field == "Audit Score") {
                if (!(auditVal >= 0 && auditVal <= 5)) {
                    this.popupPillar = true;
                    this.headerforPillar = "Alert";
                    this.msgForPillar = "Audit Score value should be less or equal to 5.";
                    this.machineDetail[index][month] = JSON.parse(JSON.stringify(this.rawMachineDetail[index][month]));
                }
            } else if (pillarId !== 4 && field == "Audit Score") {
                if (!(auditVal >= 0 && auditVal <= 100)) {
                    this.popupPillar = true;
                    this.headerforPillar = "Alert";
                    this.msgForPillar = "Audit Score value should be less or equal to 100.";
                    this.machineDetail[index][month] = JSON.parse(JSON.stringify(this.rawMachineDetail[index][month]));
                }
            }
        },
        verifyCurrent(index, month, event, field) {
            let auditVal = event.target.value;
            let pillarId = this.pillarPrevSelected;
            if (field == "Current") {
                if (pillarId == 4 && auditVal > 5) {
                    // 4 - WPO
                    this.popupPillar = true;
                    this.headerforPillar = "Alert";
                    this.msgForPillar = "WPO Pillar Step value should be less or equal to 5.";
                    this.machineDetail[index][month] = JSON.parse(JSON.stringify(this.rawMachineDetail[index][month]));
                }
                if (pillarId == 5 && auditVal > 7) {
                    // 5 - DEC
                    this.popupPillar = true;
                    this.headerforPillar = "Alert";
                    this.msgForPillar = "DEC Pillar Step value should be less or equal to 7.";
                    this.machineDetail[index][month] = JSON.parse(JSON.stringify(this.rawMachineDetail[index][month]));
                }
                if (pillarId == 6 && auditVal > 6) {
                    // 6 - CSD
                    this.popupPillar = true;
                    this.headerforPillar = "Alert";
                    this.msgForPillar = "CSD Pillar Step value should be less or equal to 6.";
                    this.machineDetail[index][month] = JSON.parse(JSON.stringify(this.rawMachineDetail[index][month]));
                }
                if (pillarId == 7 && auditVal > 6) {
                    // 7 - RE
                    this.popupPillar = true;
                    this.headerforPillar = "Alert";
                    this.msgForPillar = "RE Pillar Step value should be less or equal to 6.";
                    this.machineDetail[index][month] = JSON.parse(JSON.stringify(this.rawMachineDetail[index][month]));
                }
            }
        },
        verify(index, month, event, field) {
            this.verifyAudit(index, month, event, field);
            this.verifyCurrent(index, month, event, field);
        },
        OkForPillar() {
            this.popupPillar = false;
        },
        dataFormatter(i) {
            let objCurrent = {},
                objAudit = {};
            for (const key in this.machineDetail[3 * i + 1]) {
                if (Object.hasOwnProperty.call(this.machineDetail[3 * i + 1], key) && key !== "labels") {
                    objCurrent[key] = Number(this.machineDetail[3 * i + 1][key].value);
                }
            }
            for (const key in this.machineDetail[3 * i + 2]) {
                if (Object.hasOwnProperty.call(this.machineDetail[3 * i + 2], key) && key !== "labels") {
                    objAudit[key] = Number(this.machineDetail[3 * i + 2][key].value);
                }
            }
            return { objCurrent, objAudit };
        },
        updateHandle() {
            console.log(this.machineDetail);
            this.showLoader = true;
            // SAVECURRENTANDAUDIT
            let currentScoresList = [],
                actionScoresList = [];
            for (let i = 0; i < this.machineDetail.length / 3; i++) {
                let { objCurrent, objAudit } = this.dataFormatter(i);
                currentScoresList.push({
                    ...objCurrent,
                    lastUpdatedBy: this.$oidc.userProfile.email
                });

                actionScoresList.push({
                    ...objAudit,
                    lastUpdatedBy: this.$oidc.userProfile.email
                });
            }

            console.log(currentScoresList, actionScoresList);
            let reqbody = {
                currentScoresList,
                actionScoresList
            };
            this.showPopup = false;
            ApiCalls.httpCallDetails(Api.SAVECURRENTANDAUDIT, "post", reqbody).then((data) => {
                this.showLoader = false;
                console.log(this.pillarList, this.pillarId);
                if (data.success) {
                    let pillarName = this.pillarList.findIndex((pillar) => pillar.pillarId === Number(this.pillarPrevSelected));
                    this.errorMsg = `Current and Audit score's for ${this.pillarList[pillarName].pillarCode} pillar is updated Successfully.`; //Score Setting is Successfully Updated
                    this.headText = "";
                    this.showPopup = true;
                } else if (!data.success) {
                    this.errorMsg = "Something went wrong.";
                    this.headText = "";
                    this.showPopup = true;
                }
            });
        }
    }
};
</script>

<style scoped>
.graph-div {
    width: 25%;
    padding: 0 1%;
}

.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.plant-dd {
    width: 20.5%;
}

.asmt-bt:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.asmt-bt:hover {
    border-color: #869ed8 !important;
    color: #274ba7 !important;
    background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.asmt-bt {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.mat-needInput-con {
    margin-left: 2rem;
    display: flex;
    align-items: center;
}

.machine-action-tracker {
    margin-top: 2rem;
    width: 100%;
}

.edit-button-div {
    display: flex;
}

.input-div {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    /* width: 50px; */
}

.input-f {
    width: 30px;
    text-align: center;
    font-size: 1.33rem;
    border-radius: 4px;
    border: #313131 1px solid;
}

#machine-action-tracker th.table-success {
    background-color: #f4f6fd;
}

#machine-action-tracker th:first-child,
#machine-action-tracker th:nth-child(2) {
    background-color: white !important;
    font-weight: medium;
}

#machine-action-tracker tr:nth-child(3n-2) td:first-child {
    border-bottom: 1px solid #eae6e6 !important;
}

#machine-action-tracker tr:nth-child(3n) td {
    border-bottom: 1px solid #eae6e6 !important;
}

.machine-action-tracker-label {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1.333rem;
    font-family: "BarlowM", sans-serif;
}

.machine-action-table {
    max-height: 37rem !important;
}

.machine-action-table thead th {
    background-color: #f7f9ff !important;
}

.machine-action-table thead th,
.machine-action-table td {
    text-align: center !important;
    vertical-align: middle !important;
    min-width: 40px;
}

.save-submit-btn {
    background-color: #274ba7;
    border-color: #274ba7 !important;
    margin-top: 0.4rem;
}

.save-submit-btn:hover {
    background-color: #258cf3 !important;
    border-color: #258cf3 !important;
}

.save-submit-btn:active {
    background-color: #258cf3 !important;
    border-color: #258cf3 !important;
}

#back-btn {
    border: transparent;
    width: 11rem;
}

.save-submit-btn:focus {
    box-shadow: 0 0 0 0 rgb(130 138 145 / 50%) !important;
}

#machine-action-tracker>.machine-action-table th,
td {
    border: 0px !important;
    border-right: 1px solid #eae6e6 !important;
    border-left: 1px solid #eae6e6 !important;
}

.machine-action-table-machine {
    text-align: left !important;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

.machine-action-table th:first-child {
    width: 15%;
}

.machine-action-table td,
.machine-action-table th div {
    color: #313131 !important;
    font-weight: 100;
}

.machine-action-table td tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table tbody tr:nth-child(even) {
    background-color: #f5f5ea !important;
}

.machine-action-table tbody tr:nth-child(odd) {
    background-color: #fafaf0 !important;
}

.machine-action-table .table-light,
.machine-action-table .table-light>td,
.machine-action-table .table-light>th {
    background-color: #f0f0f0 !important;
}

.machine-action-table body tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table th.table-success {
    background-color: #f4f6fd;
}

.machine-action-table th:first-child,
.machine-action-table td:first-child {
    background-color: transparent !important;
}

.machine-action-table td.table-success {
    background-color: transparent;
}

.machine-action-table tr {
    height: 5rem;
}

.machine-action-table thead tr {
    border-bottom: 2px solid #dddddd !important;
}

.ontrack {
    width: 2rem;
    background: #3a5824;
    margin: 0 25%;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.offtrack {
    width: 2rem;
    background: #c11a3b;
    margin: 0 25%;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.row-month {
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.btn {
    margin: 0 0 0 1rem;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}</style>
